<template>

	<div class="main-contents">
		
		<div class="tit mb-7-5">모집중인 프로젝트<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="$router.go(-1)"/>
			<div class="show-date" @click="projectStart != '4' ? showChange() : ''">
				<div class="list">
					<div class="list-box show">승인여부 : </div> <SelectComp class="ap" tagName="div" type="text" list="Y:승인,N:미승인" v-model="apprYn"/>
					<span class="list-box"><nbsp n="2"/>/<nbsp n="2"/></span>
					<div class="list-box date">프로젝트 수주일 :</div> <div class="list-box cont">{{contractYmd}}</div>
				</div>				
			</div>
		</div>
		<div class="contents-body demand">

			<ProjectInfo :projectSeq="projectSeq" type="104" @goList="$router.go(-1)"/>
		</div>

	</div>

</template>

<script>

import ProjectInfo from '@/components/highpro/ProjectInfo.vue';

export default {

	components : {
		ProjectInfo
	},

	data() {
		return {
			projectSeq : 0,
			apprYn : '',
			contractYyyymmdd : '',
			contractYmd : '',
			projectStart : '',
		};
	},
	beforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');

		if(this.$route.query.projectSeq != undefined && this.$route.query.projectSeq != '') {
			this.projectSeq = Number(this.$route.query.projectSeq);
		}		
		this.getProjectAppr();
	},
	mounted() {
		// console.log('mounted');
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {
		getProjectAppr() {
			var param = {
				projectSeq : this.projectSeq
			}
			this.$.httpPost('/api/prj/adm/getProjectAppr', param)
				.then(res => {
					//console.log('getProjectAppr RESULT', res);
					this.apprYn = res.data.apprYn == 'N' ? 'N' : 'Y';
					this.contractYmd = res.data.contractYyyymmdd == undefined ? '-' : res.data.contractYyyymmdd.substr(0,4) + ". " + res.data.contractYyyymmdd.substr(4,2) + ". " + res.data.contractYyyymmdd.substr(6,2);
					this.contractYyyymmdd = res.data.contractYyyymmdd == undefined ? '-' : res.data.contractYyyymmdd;
					this.projectNm = res.data.projectNm;
					this.projectStart = res.data.projectStart;
					this.projectDivCd = res.data.projectDivCd;
				}).catch(this.$.httpErrorCommon);
		},

		showChange() {
			
			var params = {
				projectSeq : this.projectSeq,
				projectNm : this.projectNm,
				apprYn : this.apprYn,
				contractYyyymmdd : this.contractYyyymmdd == '-' ? null : this.contractYyyymmdd,
				projectDivCd : this.projectDivCd,
			}
				
			this.$.popup('/adm/prj/PRJ911P06', params)
				.then(res => {
					if(res) {
						this.$router.go(0);
					}
				});

		},
	},
};
</script>
